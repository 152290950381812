import React from 'react'
import PropTypes from 'prop-types'
import { colors, breakpoints } from '@dqp/common/styles/utilities'
import Wave from '@dqp/common/components/Wave/Wave'
import { css } from '@emotion/core'
import Title from '@dqp/common/components/Title/Title'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import ArticlesGrid from '#containers/Advice/ArticlesGrid'

const styles = {
  container: css`
    ${breakpoints({
      paddingTop: [40, 40, 60, 60, 60],
      paddingBottom: [40, 40, 60, 60, 60],
    })};
  `,
}
const AdvicePagePerTag = ({ pageContext }) => {
  const { articles, meta } = pageContext
  return (
    <PublicLayout headerColor={colors.white}>
      <SEO
        title={meta.name}
        meta={[{ name: 'theme-color', content: colors.white }]}
      />
      <div css={styles.container}>
        <div className='container'>
          <Title size='xxxLarge' color={colors.black}>
            {meta.name}
          </Title>
        </div>
        <ArticlesGrid articles={articles} />
      </div>
      <Wave fill={colors.white} bg={colors.white} />
    </PublicLayout>
  )
}

AdvicePagePerTag.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default AdvicePagePerTag
